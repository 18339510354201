:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 850px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 0.6rem;
    --vertical-padding: 0.6rem;
    --margin: 0.6rem;
    --vertical-margin: 0.6rem;
    /* Fixed header */
    --fixed-header-height: 10vh;
    /* Slick */
    --slick-slider-height: 445px;
    /* Colors */
    --text-color: #121212;
    --main-color: #cdcdcd;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: inherit;
    --header-bg-color: #fff;
    --footer-bg-color: #121212;
    --footer-text-color: #fff;
    --menu-bg-color: inherit;
    --menu-text-color: inherit;
    --menu-text-hover-color: #2F99D4;
    --menu-text-bg-color: inherit;
    --menu-text-bg-hover-color: #2F99D4;
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: OpenSans, sans-serif;
    --alternative-font-family: var(--base-font-family);
}


@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Noto Sans/all.css"; /* 400, 400i, 700, 700i */
@import "/fonts/Roboto Slab/100.css";
@import "/fonts/Roboto Slab/400.css";


html {
    height: 100%;
}

div[style*="background-image"] {
    background-size: cover;
    background-repeat: no-repeat;
}

body {
    font-weight: 400;
    font-family: 'Noto Sans', sans-serif;
    color: #000;
    font-size: 13.5px;
    line-height: 20.25px;
    letter-spacing: 0.7px;
}

.startsida .content {
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.content a, footer a {
    background: #cdcdcd;
    text-decoration: none;
}

.content a:hover {
    background: none;
}

.content a.memberimage, .content .memberimage + a, .medlem .memberimage a {
    background: none;
}

.content .messageboard a, .content .top a,  .content .contentmain a, a.allmembers {
       padding: 0 2px;
}

a.allmembers {
    margin-top: 1rem;
    display: block;
}

section {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

section .flex > * {
    margin-top: 0;
    margin-bottom: 0;
}

section .flex > h2 {
    padding-top: 0;
    padding-bottom: .5rem;
}

section.works .flex > * {
    margin-bottom: 1em;
}

.twelve.columns.centered {
    margin: 0 auto;
    max-width: 1100px;
    float: none;
}

header ul.menu a {
    padding: 0 10px;
}

header ul.menu a:hover, header ul.menu li.active a {
    background: #000;
    color: #fff;
}

footer a {
    background: #cdcdcd;
    color: #000;
    text-decoration: none;
    display: inline-block;
    padding: 0 5px;
}

/*--------------------------------------------------------------
Extras
--------------------------------------------------------------*/

.logo:hover {
    -webkit-filter: invert(100%) contrast(160%);
    filter: invert(100%) contrast(160%);
    background-color: #fff;
}

.breadcrumbs {
    list-style: none;
    display: inline-block;
    margin: 0;
    margin-top: 1rem;
}

.breadcrumbs li {
    display: inline-block;
    padding: .5rem;
}

.breadcrumbs li a {
    text-decoration: none;
    text-transform: uppercase;
    background: none;
}

.gray {
    background: #e6e6e6;
}

.middlegraylight {
    background: #cdcdcd;
}

.middlegray {
    background: #999;
}

.darkgray {
    background: #353535;
    color: #e5e5e5;
}

a.teaser, a.teaser:hover {
    text-decoration: none;
    color: #000;
}

a.teaser.first {
    background: url('/theme/intendit/images/gfx/ip-upcoming-stripes.jpg');
}

a.teaser:nth-child(4), a.teaser:last-child {
    background: #cdcdcd;
}

.boxar {
    border-right: 7px solid #fff;
    margin-left: 0%;
}

.boxar:last-child {
    border-right: 7px solid transparent;
}

.boxar.text {
    padding-left: 2%;
    height: 305px;
}

.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    width: 100%;
}

h1 {
    font-size: 1.1rem;
    line-height: 1.65rem;
    margin-top: 1.3125rem;
    margin-bottom: 1.3125rem;
    font-weight: 400;
    font-family: 'Roboto Slab', serif;
    text-transform: uppercase;
}

.medlem h1, .exhibition h1, .startsida h1, .memberofthemonth h2 {
    font-size: 1.1rem;
    line-height: 1.65rem;
    font-weight: 400;
    font-size: 2.3125rem;
    line-height: 2.625rem;
    font-weight: 100;
}

h2 {
    font-size: 1.4375rem;
    line-height: 2.625rem;
    margin-top: 2.625rem;
    margin-bottom: 1.3125rem;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;
}

h2 {
    font-size: 1.1rem;
    line-height: 1.65rem;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

h3 {
    font-size: 1.1rem;
    line-height: 1.65rem;
    margin-top: 1.3125rem;
    margin-bottom: 1.3125rem;
    font-weight: 400;
    text-transform: uppercase;
}

h4 {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    margin-top: 1.3125rem;
    margin-bottom: 0rem;
}

.seven.messageboard {
    width: 60.6666666667%;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

ul.menu li {
    margin: 0;
}

ul.menu li.last {
    margin-right: 1em;
}

.menu li, .menu li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

/*--------------------------------------------------------------
Anslagstavla
--------------------------------------------------------------*/

.messageboard {
    background: #9A9A9A;
    padding-top: 0px;
}

.posts hr {
    margin: 0;
    color: #cdcdcd;
}

/*--------------------------------------------------------------
Member of the month
--------------------------------------------------------------*/



.four.start.memberofthemonth {
    padding: 0;
    flex-basis: 31.33333%;
}

a.memberofthemonth {
    text-decoration: none;
}

/* SLICK */

.exhibition .slick {
    height: 450px;
}

.slider-for div {
    height: 450px;
}

.slick-slider {
    margin-bottom: 0px;
}

.slick-prev {
    left: 25px;
    z-index: 100;
}

.slick-next {
    right: 25px;
}

.start .slick-track div {
    border-right: 7px solid #fff;
}

.slickcontainer {
    overflow: hidden;
}

/* Exhibitions */

.exibitiontitle {
    padding: 2rem;
    text-align: center;
    max-width: 640px;
}

.exhibitionbody {
    padding: 2rem;
    max-width: 640px;
}

/*--------------------------------------------------------------
Member
--------------------------------------------------------------*/

.memberimage {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 400px;
}

.memberimage h1 {
    margin-top: 0px;
    padding-left: 1rem;
}

.memberimage div div {
    background: rgba(255,255,255,0.5);
    padding-top: 0px;
    padding-bottom: 0px;
}

.memberimage .flex > * {
    margin-bottom: 1em;
}

.memberimage a {
    text-decoration: none;
    text-transform: uppercase;
}

.works article > div:nth-of-type(odd) {
    background: #cdcdcd;
}


.audiocontrols {

   
background: #999999;
}

.audiocontrols div {

 
    padding: .5rem;
    font-size: 2rem;
 width: 100%;
    text-align: center;

    opacity: 0.5rem;
    cursor: pointer;
    opacity: .6;
}

.audiocontrols div:hover {
  opacity: 1;
  color: #fff;
}


.audiocontrols div.pause {
 display:none;
}



/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.grow {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

.site {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.content {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

header, footer {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
}

header a {
    text-decoration: none;
    color: #fff;
}

header {
    width: 100%;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
    background: #fff;
}

footer {
    margin-top: 1rem;
    background: #353535;
    color: #999999;
}

input {
    border: none;
    padding: 10px;
    border-radius: 20px;
}

footer p {
    margin-top: 0;
}

footer i.fa {
    font-size: 3rem;
    background: none;
}

footer a.facebook {
    color: #999999;
    background: none;
}



/*--------------------------------------------------------------
Exhibition archive
--------------------------------------------------------------*/

.utstallningsarkiv .gray {
    background-size: 10%;
}

h2.exhibitionyear {
    background: #cdcdcd;
    padding: .2rem 1rem;
    margin: 1rem 0;
}

.accordion-toggle {
    cursor: pointer;
}

.accordion-content {
    display: none;
    padding: 1rem;
}

.accordion-content.default {
    display: block;
}

.accordion-content span {
    display: block;
    margin: 1rem 0 1rem 0;
}

@media (max-width: 800px) {
    .menu.menu {background: #fff; }
    .teaser {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }

    .teaser:first-of-type {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .tidigareutstallningar {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .messageboardheader {
        order: -1;
    }

    .messageboard {
        order: 0;
    }

    .memberofthemonthheader {
        order: 1;
    }

    .memberofthemonth {
        order: 2;
    }
}
